

.coupon-code
    display: flex
    justify-content: center
    align-items: center
    gap: 0 16px
    .input-coupon-code
        width:70%
    .use-code
        width: 30%

.bundle-list
    font-size: 0.95rem

.text-main-product
    font-size: 0.975rem !important
    font-weight: normal

.price-text
    font-size: 1.15rem
    color:  $color-text-price
    text-align: right


.price-bundle-text
    // color:  $color-text-price
    text-align: right
    display: flex
    flex-direction: column
    justify-content: flex-start
    white-space: nowrap

.price-bundle-text.free
	color: #4CAF50;

.price-summary-text
    color:  $color-text-price

.text-color-bnn
    color: $color-bnn

.payment-type-text
    color: $color-bnn-orange

.text-red
    color: $color-bnn-red-2

.sku-product
  display: flex
  gap 0 16px
  align-items: flex-start
  img
    width: 48px
    height: 48px
    object-fit: contain

.coupon-msg-error
  color: $color-bnn-red
  margin-top: -1rem
  font-size: 1rem

.normal-price
  color: $color-bnn-gray-medium-2
  font-size: 0.85rem
  text-align: right

.text-discount
  color: $color-bnn-red

::v-deep .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea
  display none

.font-bold
  font-weight: bold

.hide-text
  font-size: 0

.coupon-msg-success
  color: $color-bnn-gray-medium-2
  font-size: 1rem
  font-weight: bold
  margin-top: -1rem

.text-deposit
  color: $color-bnn-border-dark-btn

.img-below
  width: 100%
  object-fit: contain
.pointer
  cursor: pointer

.text-nowrap
  white-space: nowrap

.product-main-layout
  display: flex
  justify-content: space-between
  width 100%
  @media small
    flex-direction: row
    flex-wrap: wrap
  .desc-main
    width 50%
    display: flex
    flex-direction: column
    justify-content: flex-start
    height 100%
    @media small
      justify-content: flex-start
      width 100%
      height auto
    @media tablet
      justify-content: flex-start
    .product-name
                color: $color-bnn-gray-medium
                font-weight: bold
                font-size: 1rem
                margin-top: 0.5rem
    .product-sku
                color: $color-bnn-gray-medium-2
  .price-main
    width 50%
    display: flex
    flex-direction: column
    justify-content: flex-start
    height 100%
    text-align: right
    @media small
      width 100%
      align-items: flex-start
    @media tablet
      justify-content: space-between
    .price-text, .price-text-2
          font-size: 1rem
          // color:  $color-text-price
          text-align: right
          font-weight: bold

    .price-text-2
          //  color: $color-bnn !important


.product-card
    display: none !important
    @media tablet
      display: block !important

.product-card

    // background: white 0% 0% no-repeat padding-box;
    border-radius: 8px;
    display: flex
    justify-content: space-between
    flex: 1 1
    padding: 1.25rem 0rem
    margin-top: 0px
    flex-direction: column
    @media screen and (max-width: 768px)
      padding: 1rem 0.5rem 0 0.5rem
      font-size: 0.85rem
    .preview
        width: 100%
        display: flex
        justify-content: flex-start
        gap: 16px 16px
        margin: 1rem 0
        .img
            width: 100px
            height: 100px
            min-width: 100px
            max-width: 100px
            min-height: 100px
            max-height: 100px
            // height: 100%
            object-fit: contain
        .desc
            display: flex
            flex-direction: column
            justify-content: flex-start
            width: 100%
            .product-name
                color: $color-bnn-gray-medium
                font-weight: bold
                margin-top: 0.5rem
            .product-sku
                color: $color-bnn-gray-medium-2

    .price
        width: 100%
        display: flex
        justify-content: flex-start
        flex-direction: column
        align-items: flex-end
        .price-text
            font-size: 1rem
            color:  $color-text-price
            text-align: right
            @media screen and (max-width: 768px)
              font-size: 0.9rem
              margin-top: 0.5rem
.action-layout
  width: 100%
  display: flex
  justify-content: flex-end
  margin: 1.5rem 0
  .action
        width 50%
        display: grid
        gap: 16px
        grid-template-columns: repeat(2, 1fr)

.summary
    display: flex
    justify-content: space-between
    margin: 1rem 0
    .count-number
      width: 50%
      color: $color-bnn-gray-medium-2
    .price-text
      width 50%
      display: flex
      flex-wrap: nowrap
      flex-direction: row
      justify-content: flex-end
      .price
        color: $color-bnn-red-2
        font-weight: bold
        width auto
        margin-left: 0.2rem

.justify-between
    justify-content: space-between !important

.margin-top-0-5
    margin-top: 0.5rem

.drop-full-payment
  font-size: 0.875rem !important
  color: $color-bnn-gray-medium-2

.is-desktop
  @media tablet
    display: none !important
.text-remove
  color: $color-blue
  cursor: pointer
  font-weight: 200

.text-white
  color: #fff !important
