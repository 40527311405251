

.cart-pre-order
    margin-top: 50px
    padding: 2rem 4rem
    @media screen and (max-width: 960px)
      padding: 1rem
      margin-top: 70px

.is-desktop
  @media tablet
    display: none !important

.gallery
    display: flex
    justify-content: center
    padding 0 10rem 0 0
    margin-top: -2.2rem
    @media screen and (max-width: 960px)
      padding: 0
      display: flex
      justify-content: center
      width: 100%

.cart-order
  margin-top: -1rem

.text-back
  color $color-tertiary
  font-size: 1rem
  text-align: right
  cursor pointer
  display:  block
  font-weight: bold
  @media small
      display:  none
  @media medium
      display: none
