
.pointer
    cursor pointer
.payment
    background white
    margin-top 1rem
.p-layout
    display flex
    flex-direction column
    align-content center
    align-items center
.payment-content-2
    width 100%
    @media medium
        width 100%
        padding 0 1rem
    @media small
        width 100%
        padding 0 1rem
.payment-content
    background black
    color white
    width 80%
    padding 1rem
    @media medium
        width 100%
    @media small
        width 100%
.channel-payment
    display flex
    align-items center
    margin-top 1rem
    width 100%
    flex-wrap wrap
.btn-bnn-submit
    background $color-bnn
    border-radius 30px;
    text-align center
    box-shadow: 0px 4px 6px #00000029;
    padding 1rem 6rem
    max-width 350px
    font-weight bold
    cursor pointer
    @media small
      padding 0.6rem 6rem
    @media medium
      padding 1rem 6rem
.btn-bnn-submit.disabled
    background $color-gray-100
    opacity 0.7
    cursor not-allowed
.text-detail-payment-delivery
    font-size 1rem
    @media medium
        font-size 0.8rem
    @media small
        font-size 0.8rem
.isLoading
  cursor not-allowed !important
  opacity 0.6

.checkout-action
   margin-top: 16px
   position relative
   display: flex
   justify-content: center
   .action
      display flex
      flex-direction: column
      gap: 16px
      width: 350px
   @media tablet
    display: flex
    flex-direction: column
    width: calc(100%+72px)
    margin-left: -36px
    .action
        width: 100%
        margin: 1.5rem 0

.footer-margin
    margin-bottom: 60px;
@media tablet
  .footer-margin
    margin-bottom: 60px;

.payment-type-layout
    width 50%
    @media medium
        width 100%
    @media small
        width 100%
.chanel-payment-grid
  display: grid
  gap: 24px
  margin-bottom 32px
  grid-template-columns: repeat(2, 1fr)
  @media medium
     grid-template-columns: repeat(1, 1fr)
     gap: 24px 0
  @media small
     grid-template-columns: repeat(1, 1fr)
     gap: 16px 0


.btn-checkout-back
    display none
    @media tablet
        display flex
        justify-content: center
        display block



.btn-back-to-cus-also-bought
  font-weight: bold !important
  font-size: 0.875rem !important
  padding: 18.5px 0 !important
  border-color: $color-secondary !important

.text-back-to-cus-also-bought
  color: $color-secondary-text

.payment-label
  margin: 1rem 0

.summary
    display: none
    justify-content: space-between
    margin: 1rem 0
    font-size: 1.15rem
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    @media tablet
      display: block
    .count-number
      width: 50%
      color: $color-bnn-gray-medium-2
      @media tablet
         font-size: 0.875rem
    .price-text
      width 50%
      display: flex
      flex-wrap: nowrap
      flex-direction: row
      justify-content: flex-end
      @media tablet
         font-size: 0.875rem
      .price
        color: $color-text-price
        font-weight: bold
        width auto
        margin-left: 0.2rem

.price-summary-cart-3-lines
  display: flex
  justify-content: space-between
  width: 100%
  align-items: center
  font-size: 1 rem

.is-tablet
  display: none
  @media tablet
    display: block

.checkout-action-fixed
   @media tablet
      flex-direction: column
      position: fixed
      width: 100% !important
      bottom: 0
      left: 0
      margin-left: 0 !important
      height: auto
      box-shadow: 1px 1px 2px 1px $color-gray-200;
      z-index 51
      margin-left: 0 !important
      margin-right: 0 !important
      padding-top: 1rem
      background: #fff
