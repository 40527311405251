

.action-layout
  display: flex
  width: 100%
  gap: 12px
  @media screen and (max-width: 960px)
    flex-wrap: wrap
  .store
    width: 50%
    padding: 12px 0
    @media screen and (max-width: 960px)
      width: 100%
  .delivery
    width: 50%
    padding: 12px 0
    @media screen and (max-width: 960px)
      width: 100%
.payment-type-layout
  display: flex
  width: 100%
  gap: 12px
  @media screen and (max-width: 960px)
    flex-direction: column
    width 100%
  .deposit
    width: 50%
    padding: 12px 0
    @media screen and (max-width: 960px)
      width: 100%

  .full-payment
    width: 50%
    padding: 12px 0
    @media screen and (max-width: 960px)
      width: 100%

::v-deep .v-select__selection
    max-width: 100%

.text-warning
  font-size: 0.875rem
  color: $color-red

  @media tablet
    font-size: 0.875rem

  @media small
    font-size: 11px
