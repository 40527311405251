

.btn-base-button
    display flex
    align-items center
    width 100%
    height 100%
    min-height: 77px
    background white
    cursor pointer
    border-radius: 8px;
    // border 1px solid $color-bnn-border-dark-btn
    box-shadow: 0 0 0 1px $color-bnn-gray-light;
    flex: 1 1
    gap 1rem
    padding 0 1rem
    transition: 0.2s ease-in-out;
    @media (hover: hover) and (pointer: fine)
        &:hover:not(.disabled)
          border-color: transparent;
          box-shadow: 0 0 0 2px $color-bnn-border;
          transition: 0.2s ease-in-out;
          // border-color: $color-dark-gray;


.btn-base-button.select
    box-shadow: 0 0 0 2px $color-bnn;
    // border-color: $color-bnn;
    //  box-shadow: 0px 2px 3px #00000029;
.btn-base-button.has-error
    border-color: red
.btn-base-button.disabled
    opacity 0.6
    cursor not-allowed
.deli-detail
    font-size 0.725rem
    @media small
       font-size 0.8rem

.label
  width 100%
  display flex
  text-align: left
  align-self: center

.btn-label
    display: flex
    justify-content: flex-start
    flex-direction: column
    align-items: flex-start

.btn-img
  width: 50px
  height: 50px
  padding: 4px
  img
    width: 100%
    height: 100%
    object-fit: contain
    object-position: center
