

.header-modal
  display: flex
  justify-content: space-between
  width: 100%
  padding: 16px

.body-modal
  padding: 16px

.header-modal-title
  font-size: 16px
  font-weight: bold
  font-family: $font-family-base !important
  align-self: center


.header-modal-close
  cursor: pointer
  color: $color-bnn-gray-light
  align-self: center

.empty-data
	text-align: center
	color: $color-bnn-gray-medium-2
	padding: 48px 0
